import React from "react";
import SectionHeader from "../common/SectionHeader";

const stickyNoteColors = ["bg-green-300", "bg-blue-300", "bg-purple-300", "bg-pink-300", "bg-yellow-300"];

const notes = [
  "GCal Integration",
  "Weekly sprint planning",
  "Team Workspaces",
  "Stickies, easels, and mind-mapping",
  "AI scheduling",
  "Command bar / quick capture",
  "Habit tracker",
  "Meetings",
  "AI-guided goal breakdown",
  "Focus timer and music",
  "Routines",
  "Asana/Jira integrations",
  "AI-Copilot",
  "Subtasks",
  "Outlining and backlinks",
  "Vision boards",
];

const getRandomTilt = () => {
  const tilts = ["-rotate-3", "-rotate-6", "rotate-3", "rotate-6"];
  const index = Math.floor(Math.random() * tilts.length);
  return tilts[index];
};

const StickyNote = ({ note, color }) => {
  const tiltClass = getRandomTilt();

  return (
    <div className={`md:w-56 md:h-56 text-xl font-medium ${color} shadow-lg p-5 flex justify-center items-center font-geist-mono transition-transform duration-300 ease-in-out hover:scale-110 ${tiltClass} hover:rotate-0`}>
      <p>{note}</p>
    </div>
  );
};

const StickyNotes = () => {
  return (
    <div>
      <SectionHeader title="More features coming soon" description="We have a huge list of useful features in our roadmap, and we design from first principles. Expect beautiful things soon." />

      <div className="flex justify-center p-0 mt-20 mb-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {notes.map((note, index) => (
            <StickyNote key={index} note={note} color={stickyNoteColors[index % stickyNoteColors.length]} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default StickyNotes;
