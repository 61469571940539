import React from "react";

const SectionHeader = ({ title, description }) => {
  return (
    <div className="mb-8 pt-20 border-t border-black mt-20">
      <h2 className="text-5xl font-bold tracking-tight text-gray-900">
        {title}
      </h2>
      <h4 className="text-2xl mt-2 max-w-2xl mx-auto tracking-tight">
        {description}
      </h4>
    </div>
  );
};

export default SectionHeader;
