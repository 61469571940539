import React, { useState, useEffect } from "react";

const Typewriter = ({ staticTextBefore, staticTextAfter, rotatingWords, speed = 100 }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);

  useEffect(() => {
    if (index < rotatingWords.length) {
      // If the current subIndex equals the length of the current word
      if (subIndex === rotatingWords[index].length + 1) {
        // Reset subIndex and move to the next word after a delay
        setTimeout(() => {
          setIndex((prev) => (prev + 1) % rotatingWords.length);
          setSubIndex(0);
        }, 3000); // Delay before starting the next word
      } else {
        // Otherwise, increment the subIndex to reveal the next character
        const timeout = setTimeout(() => {
          setSubIndex(subIndex + 1);
        }, speed);

        return () => clearTimeout(timeout);
      }
    }
  }, [subIndex, index, rotatingWords, speed]);

  const currentWord = rotatingWords[index].slice(0, subIndex);

  return (
    <>
      {staticTextBefore}
      <span className="text-purple-700">{currentWord}</span>
      {staticTextAfter}
    </>
  );
};

export default Typewriter;
