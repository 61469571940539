import React from "react";
import SectionHeader from "../common/SectionHeader";
import logo from "../../../src/assets/images/Logo Icon.png";

const PhilosophyStep = ({ number, title, description, isLast = false }) => {
  return (
    <div className="flex flex-col font-geist-mono w-full md:w-7/12">
      <div className="flex flex-col md:flex-row items-center bg-white rounded-lg overflow-hidden border border-gray-700 p-3 philosophy-step">
        <div className="flex flex-col items-center justify-center p-4 w-full md:w-64">
          <div className="text-7xl text-gray-800 font-light">{number}</div>
        </div>
        <div className="flex-grow p-6">
          <h3 className="text-center md:text-left text-2xl font-medium text-gray-800 mb-2 tracking-tight">{title}</h3>
          <p className="text-center md:text-left text-lg text-gray-600 pr-6 tracking-tight">{description}</p>
        </div>
      </div>
      {/* Render the connecting line if it's not the last item */}
      {!isLast && (
        <div className="w-full flex justify-center">
          <div className="w-px h-10 bg-gray-700"></div>
        </div>
      )}
    </div>
  );
};

const Philosophy = () => {
  return (
    <div>
      <SectionHeader title="Based on our proven GoFar system" description="QTR is modeled after GoFAR, a science-backed framework based on the methods used by top performers." />

      <div>
        <div className="w border border-gray-700 font-geist-mono inline-block p-4 px-16 rounded-lg philosophy-step uppercase text-lg font-medium">The System</div>

        <div className="w-full flex justify-center">
          <div className="w-px h-10 bg-gray-700"></div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <PhilosophyStep
          number="G"
          title="Set goals"
          description="Without a goal you won't know where you are going, and you'll end up
        wasting time on things that don't move the needle."></PhilosophyStep>

        <PhilosophyStep number="O" title="Get organized" description="Organize your thoughts in whatever way makes sense: lists, boards, trees, mindmaps, stickies, easels, notes, outlines."></PhilosophyStep>

        <PhilosophyStep number="F" title="Narrow your focus" description="Start with the big picture: what are your priorities this quarter? Then narrow your focus to weeks and days, and even time."></PhilosophyStep>

        <PhilosophyStep number="A" title="Make appointments (with yourself)" description="The secret to getting things done: put your tasks on the calendar. Treat them like appointments you wouldn't miss."></PhilosophyStep>

        <PhilosophyStep number="R" title="Reflect daily, weekly, quarterly" description="Journaling regularly gives you mental clarity and keeps you on track to your goals. This really isn't an optional activity." isLast={true}></PhilosophyStep>
      </div>

      <div>
        <div className="w-full flex justify-center">
          <div className="w-px h-10 bg-gray-700"></div>
        </div>

        <div className="w border border-gray-700 font-geist-mono inline-block p-4 px-16 rounded-lg philosophy-step uppercase text-lg font-medium">
          <img className="w-20 h-auto m-auto mb-4" src={logo} alt="QTR Logo" />
          Your best quarter ever
        </div>
      </div>
    </div>
  );
};

export default Philosophy;
