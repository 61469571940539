import React from "react";
import feature1 from "../../../src/assets/images/features/Days.png";
import feature2 from "../../../src/assets/images/features/Today3.png";
import feature3 from "../../../src/assets/images/features/journal2.png";
import feature4 from "../../../src/assets/images/features/Projects5.png";
import feature5 from "../../../src/assets/images/features/goals 3.png";
import SectionHeader from "../common/SectionHeader";
import { useInView } from "react-intersection-observer";

const Feature = ({ title, description, imageUrl, reverse }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Lower the threshold
    rootMargin: "-20px", // Adjust this value to trigger the animation a bit earlier or later
  });

  // Decide the order and animation class of elements based on `reverse` prop and inView status
  const orderClasses = reverse ? "md:flex-row-reverse" : "md:flex-row";
  const animationClass = inView ? (reverse ? "animate-rotateRight" : "animate-rotateLeft") : "";

  const imageComponent = (
    <div ref={ref} className={`${animationClass} ${orderClasses} flex-1 p-4 ${reverse ? "lg:order-2" : ""}`} style={{ flexBasis: "70%" }}>
      <img className="rounded-lg w-full h-auto" src={imageUrl} alt={title} />
    </div>
  );

  const textComponent = (
    <div className={`flex-1 text-left p-3 ${reverse ? "lg:order-1 mt-0 lg:mt-28" : "mt-0 lg:mt-28"}`} style={{ flexBasis: "30%" }}>
      <h3 className="text-3xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600 text-lg">{description}</p>
    </div>
  );

  return (
    <div className={`flex flex-col lg:flex-row mb-6 md:mb-20 ${reverse ? "lg:flex-row-reverse" : ""}`}>
      {textComponent}
      {imageComponent}
    </div>
  );
};

const Features = () => {
  return (
    <div>
      <SectionHeader title="The visual planner for busy people" description="The ultimate all-in-one productivity tool with goals, projects, tasks, calendar, notes, and journal, all in one place." />

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <Feature title="Plan your week." description="Organize your life in weekly sprints. Then zoom into a week to plan the days of that week. Can't get simpler than that." imageUrl={feature1} reverse={true} />
        <Feature title="Timeblock your day." description="Plan out your days with precision. Drag tasks directly to time slots and sync with your calendar." imageUrl={feature2} reverse={false} />
        <Feature title="Journal daily." description="Maintain separate journals for daily, weekly, quarterly, and yearly, each with its own set of guided prompts. QTR is the only digital system that gives you that kind of flexibility." imageUrl={feature3} reverse={true} />
        <Feature title="Plan projects." description="Brainstorm your projects with various thinking tools: lists, boards, trees, mindmaps, stickies, easels, and notes." imageUrl={feature4} reverse={false} />
        <Feature title="Track goals." description="Set a goal, define a timeframe, link it to a project, and track your progress." imageUrl={feature5} reverse={true} />
      </div>
    </div>
  );
};

export default Features;
