import React, { useEffect } from "react";
import SectionHeader from "../common/SectionHeader";

const Testimonials = () => {
  useEffect(() => {
    // Function to load script
    const loadScript = (src, callback) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = callback;
      script.src = src;
      document.head.appendChild(script);
    };

    // Load the iframe resizer script and initialize resizing
    loadScript("https://testimonial.to/js/iframeResizer.min.js", () => {
      // eslint-disable-next-line
      if (window.iFrameResize) {
        // eslint-disable-next-line
        window.iFrameResize({ log: false, checkOrigin: false }, "#testimonialto-qtr-tag-all-light");
      }
    });

    // Cleanup the script tag
    return () => {
      let scriptTag = document.querySelector('script[src="https://testimonial.to/js/iframeResizer.min.js"]');
      if (scriptTag) {
        document.head.removeChild(scriptTag);
      }
    };
  }, []);

  return (
    <div>
      <SectionHeader title="Loved by busy professionals" description="" />

      <iframe
        id="testimonialto-qtr-tag-all-light"
        src="https://embed-v2.testimonial.to/w/qtr?theme=light&card=base&loadMore=on&initialCount=20&tag=all"
        frameBorder="0"
        scrolling="no"
        style={{ width: "100%", height: "500px" }} // Adjust height as needed
      ></iframe>
    </div>
  );
};

export default Testimonials;
