import React from "react";
import "./Home.css"; // Assuming you have a Home.css for your styling
import Hero from "./Hero";
import Logos from "./Logos";
import Features from "./Features";
import Philosophy from "./Philosophy";
import SingleTestimonial from "./SingleTestimonial";
import testimonial1 from "../../../src/assets/images/testimonials/avatar2.png";
import testimonial2 from "../../../src/assets/images/testimonials/rachel.jpeg";
import testimonial3 from "../../../src/assets/images/testimonials/michael.jpeg";
import Testimonials from "./Testimonials";
import StickyNotes from "./StickyNotes";
import CTA from "../common/CTA";
import Pricing from "./Pricing";
import Tips from "./Tips";
import FAQ from "./FAQ";

const Home = () => {
  return (
    <main className="max-w-7xl mx-auto px-3 md:px-0">
      <Hero />
      <Logos />
      <SingleTestimonial
        imageSrc={testimonial1}
        altText="A description of the image for accessibility"
        testimony="As a founder, I used to begin my days swamped with countless priorities. QTR helps me build a bird's eye view of my quarter and methodically narrow my focus for each week and day, and even time slots. With QTR, I now start my days with clarity, knowing I'm moving toward my goals."
        name="Swami Venkataramani"
        role="CEO at QTR"
      />
      <SingleTestimonial
        imageSrc={testimonial2}
        altText="A description of the image for accessibility"
        testimony="QTR is gorgeous and refreshingly unique. It's a perfect blend of task and time management, and I love how everything is tied to your goals. It's almost like Asana, Notion, your calendar and journal, and an OKR tool came together!"
        name="Rachel Chen"
        role="Founder at Stealth Startup"
        reverse={true}
      />
      <CTA label="I want to try QTR" />
      <section id="features">
        <Features />
      </section>
      <CTA />
      <section id="system">
        <Philosophy />
      </section>
      <Testimonials />
      <SingleTestimonial
        imageSrc={testimonial3}
        altText="A description of the image for accessibility"
        testimony="What QTR does that no other productivity tool does is that it combines long-term planning with daily planning. Not only can I plan my day and week with time-blocking, but I can also plan the next few weeks, months, quarters, or the whole year. I love how flexible it is."
        name="Michael Seyoum"
        role="Lead Developer at Defense Language Institute"
      />
      <section id="pricing">
        <Pricing />
        <FAQ />
      </section>
      <section id="stickynotes">
        <StickyNotes />
      </section>

      <CTA label="I want to try QTR" />
    </main>
  );
};

export default Home;
