import logos from "../../assets/images/brand-logos/Logos.png";
import React from "react";

const Logos = () => {
  return (
    <div className="py-14">
      <div className="mx-auto max-w-2xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-medium leading-8 text-gray-600">
          Trusted by smart, busy professionals
        </h2>

        <img src={logos} alt="Logos" className="w-96 mx-auto mt-3" />
      </div>
    </div>
  );
};

export default Logos;
