import React, { useEffect, useState } from "react";
import SectionHeader from "../common/SectionHeader";
import ButtonGroup from "antd/es/button/button-group";
import { Button } from "antd";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import "./Home.css"; // Ensure this is correctly imported
import { useInView } from "react-intersection-observer";
import CTA from "../common/CTA";

const PricingCard = ({ features, featuresTitle, isRecommended }) => {
  const [pricingOption, setPricingOption] = useState("Yearly");
  const price = pricingOption === "Yearly" ? "$14.99" : "$21.99";
  const priceSubtitle = pricingOption === "Yearly" ? "per month, billed annually" : "per month, billed monthly";
  const buttonLabel = pricingOption === "Yearly" ? "Try QTR for FREE" : "Try QTR for FREE";
  const buttonHref = pricingOption === "Yearly" ? "https://buy.stripe.com/3cs7vMg4zbqn3Vm3ch" : "https://buy.stripe.com/6oE5nE19F9ifcrS14a";

  return (
    <div className={`relative pricing-card rounded-2xl border border-gray-700 text-center p-8 md:w-96 w-full ${isRecommended ? "bg-white recommended -m-3 z-10 pt-14 shadow-2xl" : ""}`}>
      <span className="inline-flex -space-x-px overflow-hidden rounded-md border bg-white shadow-sm">
        <button
          className={`inline-block px-4 py-2 text-sm font-medium font-geist-mono uppercase
      ${pricingOption === "Yearly" ? "text-white bg-purple-700" : "text-gray-700 hover:bg-gray-50"}
      focus:relative`}
          onClick={() => setPricingOption("Yearly")}>
          Yearly
        </button>
        <button
          className={`inline-block px-4 py-2 text-sm font-medium font-geist-mono uppercase 
      ${pricingOption === "Monthly" ? "text-white bg-purple-700" : "text-gray-700 hover:bg-gray-50"}
      focus:relative`}
          onClick={() => setPricingOption("Monthly")}>
          Monthly
        </button>
      </span>
      <p className="text-4xl font-semibold mt-8 pb-2">{price}</p>
      <p className="text-gray-600">{priceSubtitle}</p>

      <a href={buttonHref} target="_blank" rel="noreferrer" className={`text-lg font-geist-mono block border-gray-700 border font-medium py-4 px-10 my-8 w-full transition duration-300 ${isRecommended ? "bg-black hover:bg-gray-700 text-white" : "hover:bg-white"}`}>
        {buttonLabel}
      </a>
      {/* <CTA label={buttonLabel} small={true} className="w-full my-8 transition duration-300" /> */}

      <ul className="text-left font-geist-mono">
        {featuresTitle && <li className="py-2 font-bold text-lg">{featuresTitle}</li>}
        {features.map((feature, index) => (
          <li key={index} className="py-2 border-b border-gray-200 text-lg">
            <i className="fas fa-check-circle text-green-500 pr-2"></i>
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ROICard = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Lower the threshold
    rootMargin: "-20px", // Adjust this value to trigger the animation a bit earlier or later
    triggerOnce: true, // Set to true if you want the animation to happen only once
  });

  const cardStyle = inView ? "slide-in" : "";

  return (
    <div ref={ref} className={`relative pricing-card rounded-2xl border border-gray-700 text-center p-8 md:w-96 w-full ${cardStyle}`}>
      <div className={`font-geist-mono font-semibold uppercase text-xl mb-2`}>Return on Investment</div>
      <p className="text-4xl mt-8 font-geist-mono italic">≈$2985</p>
      <p className="text-gray-600 mt-2 mb-8">each month</p>
      <p className="text-left font-geist-mono mt-8">QTR is an investment. Studies show we lose 3 hours daily to distractions and mind-wandering. QTR helps you transform those hours to productive use.</p>

      <table className="w-full table-auto text-left border-collapse mt-8 font-geist-mono">
        <thead>
          <tr className="border-b-2 border-slate-400">
            <th className="pb-2">Calculation</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-slate-300">
            <td className="py-2">Hours lost each week</td>
            <td className="py-2">15 hours</td>
          </tr>
          <tr className="border-b border-slate-300">
            <td className="py-2">Assumed hourly rate</td>
            <td className="py-2">$50 / hour</td>
          </tr>
          <tr className="border-b border-slate-300">
            <td className="py-2">Value of 15 hours</td>
            <td className="py-2">$3000</td>
          </tr>
          <tr className="border-b border-slate-300">
            <td className="py-2">Cost of QTR</td>
            <td className="py-2">$14.99</td>
          </tr>
          <tr className="border-b border-slate-300">
            <td className="py-2">Total ROI per month</td>
            <td className="py-2">$2985</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Pricing = () => {
  const tiers = [
    {
      features: ["✓ Task management", "✓ Time blocking", "✓ Calendar integration", "✓ Goal tracking", "✓ Daily planning", "✓ Weekly planning", "✓ Multi-week planning", "✓ Daily Notes", "✓ Daily Journaling", "✓ Project management", "✓ Kanban boards"],
    },
  ];

  return (
    <div>
      <SectionHeader title="Become ultra-organized" description="Start your 7-day free trial. Cancel anytime." />

      <div className="flex flex-col md:flex-row justify-center items-center mt-16 space-y-4 md:space-y-0">
        {tiers.map((tier, index) => (
          <PricingCard key={index} features={tier.features} featuresTitle={tier.featuresTitle} isRecommended={index === 0} />
        ))}

        <ROICard />
      </div>
    </div>
  );
};

export default Pricing;
