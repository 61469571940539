import React from "react";
import "./Terms.css"; // Tailwind CSS will be applied, ensure this file imports Tailwind CSS

const Terms = () => {
  return (
    <div className="about min-h-screen py-12">
      <h1 className="text-4xl font-bold mb-6">Terms of Service</h1>
      <div className="mx-auto max-w-2xl space-y-6 text-left text-lg">
        <p>
          1. Terms
          <br />
          By accessing the website at https://qtr.ai, you are agreeing to be
          bound by these terms of service, all applicable laws and regulations,
          and agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site. The materials contained
          in this website are protected by applicable copyright and trademark
          law.
        </p>

        <p>
          2. Use License
          <br />
          Permission is granted to temporarily download one copy of the
          materials (information or software) on QTR's website for personal,
          non-commercial transitory viewing only. This is the grant of a
          license, not a transfer of title, and under this license you may not:
          modify or copy the materials; use the materials for any commercial
          purpose, or for any public display (commercial or non-commercial);
          attempt to decompile or reverse engineer any software contained on
          QTR's website; remove any copyright or other proprietary notations
          from the materials; or transfer the materials to another person or
          “mirror” the materials on any other server. This license shall
          automatically terminate if you violate any of these restrictions and
          may be terminated by QTR at any time. Upon terminating your viewing of
          these materials or upon the termination of this license, you must
          destroy any downloaded materials in your possession whether in
          electronic or printed format.
        </p>

        <p>
          3. Disclaimer
          <br />
          The materials on QTR's website are provided on an ‘as is’ basis. QTR
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties including, without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, or non-infringement of intellectual property or other
          violation of rights. Further, QTR does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its website or otherwise
          relating to such materials or on any sites linked to this site.
        </p>

        <p>
          4. Limitations
          <br />
          In no event shall QTR or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption) arising out of the use or inability to
          use the materials on QTR's website, even if QTR or a QTR authorized
          representative has been notified orally or in writing of the
          possibility of such damage. Because some jurisdictions do not allow
          limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply
          to you.
        </p>

        <p>
          5. Accuracy of materials
          <br />
          The materials appearing on QTR's website could include technical,
          typographical, or photographic errors. QTR does not warrant that any
          of the materials on its website are accurate, complete or current. QTR
          may make changes to the materials contained on its website at any time
          without notice. However QTR does not make any commitment to update the
          materials.
        </p>

        <p>
          6. Links
          <br />
          QTR has not reviewed all of the sites linked to its website and is not
          responsible for the contents of any such linked site. The inclusion of
          any link does not imply endorsement by QTR of the site. Use of any
          such linked website is at the user’s own risk.
        </p>

        <p>
          7. Modifications
          <br />
          QTR may revise these terms of service for its website at any time
          without notice. By using this website you are agreeing to be bound by
          the then current version of these terms of service.
        </p>

        <p>
          8. Governing Law
          <br />
          These terms and conditions are governed by and construed in accordance
          with the laws of California and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </p>
      </div>
    </div>
  );
};

export default Terms;
