// src/components/Home/Hero.js

import React from "react";
import heroImage from "../../assets/images/Daily Review.png";
import Typewriter from "../common/Typewriter";
import CTA from "../common/CTA";
import "./Home.css"; // Importing the CSS file for animations

const Hero = () => {
  const rotatingWords = ["weekly", "daily", "quarterly", "yearly"];

  return (
    <div className="text-center pt-7 md:pt-20 px-6">
      <h1 className="animate-fadeDown mx-auto max-w-3xl text-7xl text-gray-800 font-bold mb-2 hidden md:block">
        <Typewriter staticTextBefore="The most advanced " rotatingWords={rotatingWords} staticTextAfter=" planner" speed={200} />
      </h1>

      <h1 className="animate-fadeDown mx-auto text-4xl text-gray-800 font-bold mb-2 md:hidden">The most advanced weekly planner</h1>

      <p className="animate-fadeIn mx-auto max-w-2xl md:text-2xl text-xl text-gray-600 mb-8 mt-5 font-medium">Organize life in weekly sprints, plan days with precision, and achieve your dreams 13 weeks at a time.</p>

      <CTA label="Get organized now" />

      <div className="mt-12 md:mt-16">
        <img src={heroImage} alt="Hero" className="animate-flipUp mx-auto w-full" />
      </div>
    </div>
  );
};

export default Hero;
