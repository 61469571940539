import React from "react";
import SectionHeader from "../common/SectionHeader";

const FAQ = () => {
  const faqs = [
    {
      question: "There are plenty of to-do apps. What makes QTR different?",
      answer:
        "QTR isn't just a to-do app. It's a goal achievement platform that replaces your entire productivity stack: goals, tasks, notes, journal, calendar, and projects. On QTR, you're not just managing tasks. You're managing time. You're controlling the big picture. You're navigating toward your goals.",
    },
    {
      question: "I use physical planners and journals. Will QTR still help me?",
      answer:
        "We like the charm of analog too, and here's a secret: QTR is heavily inspired by physical journals. However, for the modern knowledge worker, 100% analog is impractical. QTR is designed to complement, not replace, your pen and paper. We hope you'll notice over time how QTR bridges the gap between analog and digital.",
    },
    {
      question: "Does QTR have AI features?",
      answer:
        "The current version of QTR lays the groundwork, and our next release is set to revolutionize your experience with AI features. From auto-scheduling to intuitive voice and image recognition, QTR will evolve to understand your daily patterns and become your personal productivity assistant.",
    },
    {
      question: "Does QTR integrate with other apps?",
      answer: "Yes, QTR syncs with Google Calendar. We're actively working on adding other integrations including Outlook, Asana, Slack, and Jira. We love having everything in one place.",
    },
    {
      question: "Is there a team version of QTR?",
      answer: "It's in the works! We’re designing it from first principles and we fully expect it to change how teams collaborate to achieve their goals. We expect it to transform any team into a high-performing team.",
    },
    {
      question: "Is there a mobile app?",
      answer:
        "QTR is optimized for desktop use because it's designed for visual planning. But, good news–we're working on a companion mobile app! It'll let you quickly add tasks and check your schedule when you're out and about. Over time, we plan to add more features to make the mobile app just as powerful as the desktop version.",
    },
    {
      question: "Can I try QTR before subscribing?",
      answer: "Definitely! We offer a 7-day free trial to let you experience the full capabilities of QTR. It’s the perfect way to see how it fits into your productivity routine.",
    },
    {
      question: "Why is it called QTR?",
      answer:
        "QTR stands for Quarter. QTR helps you create a bird’s eye view of your entire quarter by visually spreading tasks across a 13-week canvas. But here's a little secret: QTR also lets you zoom out and plan for the whole year. It's about seeing both the small details and the big picture easily.",
    },
  ];

  return (
    <div>
      <SectionHeader title="FAQ" description="" />

      <div className="max-w-4xl mx-auto divide-y divide-gray-300 rounded-xl border border-gray-700 bg-white">
        {faqs.map((faq, index) => (
          <details key={index} className="group p-6 [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
              <h2 className="text-lg font-medium text-left">{faq.question}</h2>
              <span className="relative h-5 w-5 shrink-0">
                {/* SVG icons for expand/collapse */}
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </summary>
            <p className="mt-4 leading-relaxed text-gray-700 text-left">{faq.answer}</p>
          </details>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
